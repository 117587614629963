<template>
    <header class="clnt-header">
        <div class="clnt-header__top">
            <div class="lds-container lds-container_full">
                <div class="clnt-header__strip">
                    <div class="clnt-header__left">
                        <button class="clnt-header__toggle"
                                aria-label="Открыть меню"
                                @click="openMenu()"
                                v-if="navList.length">
                            <span />
                        </button>
                        <router-link class="clnt-header__logo"
                                     to="/">
                            <img src="@/assets/images/logo-leads.svg"
                                 alt="Leads">
                        </router-link>
                    </div>
                    <div class="clnt-header__widget">
                        <template v-if="false">
                            <SoundCloud
                                playlist="1367528860"
                                :mini="false" />
                        </template>
                        <template v-if="!mqMobile && challengeAvailable">
                            <div class="clnt-header__widget-item">
                                <a @click="openEasterChallenge"
                                   class="clnt-header__btn lds-btn lds-btn_fill -product">
                                    <SvgIcon name="easter-egg" />
                                    Розыгрыш
                                </a>
                            </div>
                        </template>

                        <template v-if="!mqMobile">
                            <div class="clnt-header__widget-item">
                                <a href="https://t.me/leads_sales_lab_bot?start=lk"
                                   target="_blank"
                                   rel="noopener"
                                   class="clnt-header__btn lds-btn lds-btn_fill -blue">
                                    <SvgIcon name="telegram-bot" />
                                    Чат-бот
                                </a>
                            </div>
                        </template>

                        <HeaderWidgetBalance v-if="!maintenance"
                                             class="clnt-header__widget-item" />
                        <HeaderWidgetSupport class="clnt-header__widget-item"
                                             v-if="false" />
                        <HeaderWidgetAccount class="clnt-header__widget-item" />
                    </div>
                </div>
            </div>
        </div>
        <div class="clnt-header__bottom"
             v-if="mqMobile">
            <div class="lds-container lds-container_full">
                <div class="clnt-header__strip clnt-header__strip_right">
                    <div class="clnt-header__widget">
                        <div class="clnt-header__widget-item"
                             v-if="challengeAvailable">
                            <a @click="openEasterChallenge"
                               class="clnt-header__btn lds-btn lds-btn_fill -product">
                                <SvgIcon name="easter-egg" />
                                Розыгрыш
                            </a>
                        </div>

                        <div class="clnt-header__widget-item">
                            <a href="https://t.me/leads_sales_lab_bot?start=lk"
                               target="_blank"
                               rel="noopener"
                               class="clnt-header__btn lds-btn lds-btn_fill -blue">
                                <SvgIcon name="telegram-bot" />
                                Чат-бот
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clnt-nav"
             :class="{'clnt-nav_active': menuOpen}"
             v-if="credential && navList.length">
            <div class="clnt-nav__top"
                 v-if="!mqDesktopLarge">
                <div class="clnt-nav__top-title">
                    Меню
                </div>
                <button class="clnt-nav__top-close"
                        aria-label="Закрыть меню"
                        @click="closeMenu()" />
            </div>
            <div class="clnt-nav__body">
                <div class="lds-container lds-container_full">
                    <nav class="clnt-nav__menu">
                        <div class="clnt-nav__menu-item">
                            <router-link to="/"
                                         class="clnt-nav__menu-link clnt-nav__menu-link_main">
                                Главная
                            </router-link>
                        </div>
                        <HeaderNavTree :item="item"
                                       v-for="(item, index) in navList"
                                       :key="index" />
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import SoundCloud from 'vue-soundcloud-player';
import SvgIcon from '@/components/common/SvgIcon';
import HeaderNavTree from './HeaderNavTree.vue';
import HeaderWidgetAccount from './HeaderWidgetAccount.vue';
import HeaderWidgetBalance from './HeaderWidgetBalance.vue';
import HeaderWidgetSupport from './HeaderWidgetSupport.vue';

export default {
    name: 'HeaderBar',
    components: {
        HeaderNavTree,
        HeaderWidgetAccount,
        HeaderWidgetBalance,
        HeaderWidgetSupport,
        SvgIcon,
        SoundCloud,
    },
    data() {
        return {
            menuOpen: false,
        };
    },
    computed: {
        ...mapState('authorization', [
            'credential',
        ]),
        ...mapState('easterEggs', [
            'challengeAvailable',
        ]),
        ...mapGetters('easterEggs', [
            'isWelcomeState',
        ]),
        ...mapState('account', [
            'accountInfo',
        ]),
        ...mapState('common', [
            'maintenance',
        ]),
        navList() {
            const routes = this.$router.options.routes;
            return routes.filter(this.isNav);
        },
        mqDesktopLarge() {
            return this.$mq === 'xl';
        },
        mqMobile() {
            return this.$mq === 'sm' || this.$mq === 'xs';
        },
    },
    watch: {
        $route() {
            this.closeMenu();
        },
        mqDesktopLarge(newValue) {
            if (newValue) {
                this.closeMenu();
            }
        },
    },
    created() {
        this.loadParticipantProgress();
    },
    methods: {
        ...mapActions('easterEggs', [
            'showWelcomeModal',
            'loadParticipantProgress',
        ]),
        openMenu() {
            this.menuOpen = true;
            document.body.style.overflow = 'hidden';
        },
        closeMenu() {
            this.menuOpen = false;
            document.body.style.overflow = '';
        },
        isNav(item) {
            return item.hasOwnProperty('meta') && item.meta.hasOwnProperty('nav');
        },
        openEasterChallenge() {
            if (this.isWelcomeState) {
                this.showWelcomeModal();
            } else if (this.$router.currentRoute.path !== '/easterEggsChallenge') {
                return this.$router.push('/easterEggsChallenge');
            }
        },
    },
};
</script>

<template>
    <div class="clnt-widget"
         v-click-outside="hideDropdown"
         :class="{
             'clnt-widget_active': isActive,
             'clnt-widget_desktop': mqDesktop,
         }">
        <button class="clnt-widget__btn"
                aria-label="Поддержка"
                @click="toggleDropdown()">
            <span class="clnt-widget__ico">
                <SvgIcon name="support" />
            </span>
        </button>
        <div class="clnt-widget__body">
            <div class="clnt-widget__popup">
                <div class="clnt-widget__popup-caption">
                    Поддержка
                </div>
                <div class="clnt-widget__popup-content">
                    <div class="clnt-widget__popup-list">
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                Менеджер:
                            </div>
                            <div class="clnt-widget__popup-value">
                                Иван
                            </div>
                        </div>
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                Контакты:
                            </div>
                            <div class="clnt-widget__popup-value">
                                <a href="mailto:leads@leads.su"
                                   class="lds-link">
                                    leads@leads.su
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="clnt-widget__popup-actions">
                        <a href="#"
                           class="lds-btn lds-btn_fill -blue">
                            Задайте ваш вопрос
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import SvgIcon from '@/components/common/SvgIcon.vue';

export default {
    name: 'HeaderWidgetSupport',
    components: {
        SvgIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        mqDesktop() {
            return this.$mq === 'xl';
        },
    },
    watch: {
        mqDesktop(newValue) {
            if (!newValue) {
                this.hideDropdown();
            }
        },
    },
    methods: {
        toggleDropdown() {
            if (!this.mqDesktop) {
                this.isActive = !this.isActive;
            }
        },
        hideDropdown() {
            this.isActive = false;
        },
    },
};
</script>

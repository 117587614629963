<template>
    <div class="container-market">
        <div class="page-market">
            <h1>Общие положения</h1>
            <ol>
                <li>
                    Валюта <a href="/market/app">LEADS.MARKET</a> — LEADS.COINS.
                </li>
                <li>
                    Только LEADS.COINS можно обменять на товары <a href="/market/app">LEADS.MARKET</a>.
                </li>
                <li>
                    LEADS.COINS предназначены только для использования в <a href="/market/app">LEADS.MARKET</a>. Их нельзя
                    обменять на деньги и вывести из кабинета.
                </li>
            </ol>

            <h2>Правила начисления</h2>
            <ol>
                <li>
                    LEADS.COINS начисляются за каждую вышедшую из HOLDa конверсию по офферам, а также, за вывод средств на
                    определенные каналы.
                </li>
                <li>
                    Количество начисляемых LEADS.COINS зависит от индивидуальных условий по офферам, поэтому может отличаться у
                    разных вебмастеров.
                </li>
                <li>Количество начисляемых LEADS.COINS зависит от выбранного канала для вывода средств.</li>
                <li>Историю своих начислений вы можете посмотреть <a href="/financeAccounting/pointsHistory">здесь</a>.</li>
                <li>При наличии у вебмастера нескольких аккаунтов, LEADS.COINS суммируются на основном аккаунте.</li>
                <li>
                    Если вы хотите привязать дополнительные аккаунты или отвязать текущие, обратитесь, пожалуйста, к своему
                    аккаунт-менеджеру.
                </li>
                <li>
                    В акции участвуют только те офферы, в карточке которых указаны акционные баллы. За конверсии по другим
                    офферам LEADS.COINS не начисляются.
                </li>
                <li>Текущее количество заработанных LEADS.COINS отражается в личном кабинете вебмастера.</li>
                <li>Баланс заработанных LEADS.COINS обновляется сразу после выхода конверсии из холда.</li>
                <li>По итогам корректировки месяца, начисленные LEADS.COINS могут быть списаны за отклоненные конверсии.</li>
            </ol>

            <h2>Порядок покупки</h2>

            <ol>
                <li>
                    При обмене LEADS.COINS на товары в <a href="/market/app">LEADS.MARKET</a> соответствующая сумма списывается
                    со счета вебмастера
                </li>
                <li>
                    Вебмастер может обменять LEADS.COINS на призы из <a href="/market/app">LEADS.MARKET</a> в любое время при
                    накоплении достаточной суммы.
                </li>
                <li>Доставка призов в регионы РФ осуществляется за счет партнерской сети LEADS.SU.</li>
                <li>Доставка в другие страны осуществляется за счет вебмастера.</li>
                <li>В отдельные регионы доставка может быть невозможной. Подробнее уточняйте у своего аккаунт-менеджера.</li>
                <li>
                    За статусом доставки заказанных товаров вы можете следить на странице
                    <a href="/market/app#/orders">«Мои заказы»</a>
                </li>
            </ol>

            <h2>Иные положения</h2>
            <ol>
                <li>
                    Условия работы могут быть изменены партнерской сетью LEADS, следите за обновлениями, чтобы оставаться в
                    курсе событий.
                </li>
                <li>
                    Список призов, представленный на витрине <a href="/market/app">LEADS.MARKET</a>,
                    может быть изменен в любое время
                </li>
                <li>
                    Если какого-то товара или приза нет в наличии, то партнерская программа LEADS.SU вправе отказать в его
                    доставке, сообщив об этом, и вернув в полном объеме акционные баллы LEADS.COINS, если те были потрачены
                </li>
                <li>Стоимость призов может меняться в зависимости от текущего курса USD.</li>
                <li>При отсутствии выбранной модели товара будет произведена замена товара на аналогичный.</li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rules',
};
</script>

<template>
    <div class="lds-app"
         v-cloak>
        <div class="clnt-login-page">
            <div class="clnt-login-page__inner">
                <div class="clnt-login-page__header">
                    <div class="clnt-login-page__logo">
                        <img src="@/assets/images/logo-leads.svg"
                             alt="LEADS">
                    </div>
                </div>
                <div class="clnt-login-page__content">
                    <h1 class="clnt-login-page__title">
                        {{ heading }}
                    </h1>
                    <router-view />
                </div>
                <div class="clnt-login-page__footer">
                    <p>
                        Copyright &copy;
                        <a href="http://leads.su/"
                           target="_blank"
                           rel="noopener">
                            LEADS.SU
                        </a>
                        2010-{{ currentYear }}.
                        <br>
                        Все права защищены.
                    </p>
                    <p>
                        <a href="tel:+78005008344">+7 (800) 500-8344</a>&#32;
                        <a href="mailto:support@leads.su"
                           class="lds-link">support@leads.su</a>
                    </p>
                    <p v-if="captchaPolicy"
                       v-html="captchaPolicy" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'LayoutLogin',
    data() {
        return {
            currentYear: new Date().getFullYear(),
            captchaPolicy: this.$yaCaptcha ? this.$yaCaptcha.policy : null,
        };
    },
    computed: {
        ...mapState('common', [
            'heading',
        ]),
    },
};
</script>

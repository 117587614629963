<template>
    <v-easy-dialog v-if="currentRound && collectedModal && collectedModal.show"
                   v-model="showModal">
        <div>
            <div class="clnt-easter-dialog">
                <button class="clnt-easter-dialog__close"
                        @click="closeModal">
                    <SvgIcon name="close" />
                </button>
                <div class="clnt-easter-dialog__title -top"
                     v-if="!currentRound.isAllCollected">
                    Есть "пасхалка"! Ура, поздравляем!
                </div>
                <div class="clnt-easter-dialog__title -top"
                     v-if="currentRound.isAllCollected">
                    Вы сделали это! Все "пасхалки" найдены
                </div>

                <div class="clnt-easter-dialog__cover">
                    <img src="@/assets/images/easterEggs/easter_egg_modal_cover_found.png"
                         v-if="!currentRound.isAllCollected">
                    <img src="@/assets/images/easterEggs/easter_egg_modal_cover_found_all.png"
                         v-if="currentRound.isAllCollected">
                </div>

                <div class="clnt-easter-dialog__subtitle accent"
                     v-if="!currentRound.isAllCollected">
                    Вы стали еще на одну "пасхалку" ближе к победе. Но на этом не стоит останавливаться
                </div>
                <div class="clnt-easter-dialog__subtitle accent"
                     v-if="currentRound.isAllCollected">
                    Осталось узнать ваше время и итоговое место
                </div>

                <div class="clnt-easter-dialog__subtitle"
                     v-if="!currentRound.isAllCollected">
                    Загляните на страницу розыгрыша и проверьте, нет ли других активных "пасхалок"
                </div>

                <div class="clnt-easter-dialog__subtitle"
                     v-if="currentRound.isAllCollected">
                    Все это мы расскажем на вебинаре в период 20-25 декабря, где подведем итоги третьего тура и вручим
                    призы победителям
                </div>

                <div class="clnt-easter-dialog__action">
                    <div class="lds-popover lds-popover_size-sm">
                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                @click="closeAndGoToEasterPage">
                            Перейти на страницу розыгрыша
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>

import {
    mapState,
    mapActions,
} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import metaMixin from '@/mixins/metaMixin';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'EasterEggsModal',
    components: {
        SvgIcon,
        VEasyDialog,
    },
    mixins: [
        metaMixin,
    ],
    computed: {
        ...mapState('easterEggs', [
            'collectedModal',
            'currentRound',
        ]),
        showModal: {
            set() {
            },
            get() {
                return this.collectedModal.show;
            },
        },
    },
    methods: {
        ...mapActions('easterEggs', [
            'closeModal',
        ]),
        closeAndGoToEasterPage() {
            this.closeModal();
            if (this.$router.currentRoute.path !== '/easterEggsChallenge') {
                return this.$router.push('/easterEggsChallenge');
            }
        },
    },
};
</script>

<template>
    <div class="lds-heading-links">
        <ul class="lds-breadcrumbs"
            v-if="breadcrumbs.length">
            <li class="lds-breadcrumbs__item">
                <router-link class="lds-breadcrumbs__link"
                             to="/">
                    Главная
                </router-link>
            </li>
            <li class="lds-breadcrumbs__item"
                v-for="(item, index) in breadcrumbs"
                :key="index">
                <template v-if="item.name">
                    <router-link class="lds-breadcrumbs__link"
                                 :to="item.path"
                                 v-if="(index + 1) != breadcrumbs.length">
                        {{ item.name }}
                    </router-link>
                    <span class="lds-breadcrumbs__current"
                          v-else>
                        {{ item.name }}
                    </span>
                </template>
            </li>
        </ul>
        <ul class="lds-heading-links__list">
            <li class="lds-heading-links__item">
                <router-link :to="{name: 'Help'}"
                             class="lds-link">
                    Справка
                </router-link>
            </li>
            <li class="lds-heading-links__item">
                <router-link :to="{name: 'Feedback'}"
                             class="lds-link">
                    Предложить улучшение
                </router-link>
            </li>
            <li class="lds-heading-links__item easter-egg-text"
                v-if="showEasterEgg && !!currentEasterEgg && currentEasterEgg.needShow"
                @click="collect(currentEasterEgg)">
                :)
            </li>
        </ul>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {tour1egg6} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'Breadcrumbs',
    computed: {
        ...mapState('common', [
            'breadcrumbs',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        currentEasterEgg() {
            return this.getAvailable(tour1egg6);
        },
        showEasterEgg() {
            return this.$route.path === '/statistics/dynamic';
        },
    },
    created() {
        this.check(tour1egg6);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
    },
};
</script>

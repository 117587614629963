<template>
    <v-easy-dialog v-model="showModal">
        <div>
            <div class="clnt-easter-dialog">
                <button class="clnt-easter-dialog__close"
                        @click="closeNextStepModal">
                    <SvgIcon name="close" />
                </button>
                <div class="clnt-easter-dialog__cover">
                    <div class="clnt-easter-dialog__cover__container">
                        <img class="-top"
                             src="@/assets/images/easterEggs/easter_egg_modal_next.png">
                        <img class="-bg"
                             src="@/assets/images/easterEggs/easter_egg_modal_next_bg.png">
                    </div>
                </div>

                <div class="clnt-easter-dialog__title">
                    Вы стали на один шаг ближе к пасхалке
                </div>
                <div class="clnt-easter-dialog__subtitle accent">
                    Теперь она активна и уже проявилась. Осталось ее найти)
                </div>

                <div class="clnt-easter-dialog__action">
                    <div class="lds-popover lds-popover_size-sm">
                        <button class="lds-btn lds-btn_fill lds-btn_size-sm -primary"
                                @click="closeNextStepModal">
                            Вперёд к поиску
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </v-easy-dialog>
</template>

<script>

import {
    mapState,
    mapActions,
} from 'vuex';
import VEasyDialog from 'v-easy-dialog';
import metaMixin from '@/mixins/metaMixin';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'EasterEggsNextStepModal',
    components: {
        SvgIcon,
        VEasyDialog,
    },
    mixins: [
        metaMixin,
    ],
    computed: {
        ...mapState('easterEggs', [
            'nextStepModal',
        ]),
        showModal: {
            set() {
            },
            get() {
                return this.nextStepModal.show;
            },
        },
    },
    methods: {
        ...mapActions('easterEggs', [
            'closeNextStepModal',
        ]),
    },
};
</script>

<template>
    <div>
        <easter-eggs-rules-article
            v-if="challengeAvailable" />

        <NotFound v-else-if="!challengeAvailable" />
        <router-view v-else />
    </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import NotFound from '@/components/common/NotFound';
import EasterEggsRulesArticle from '@/components/easterEggs/easterEggsRules';

export default {
    name: 'EasterEggsRules',
    components: {
        EasterEggsRulesArticle,
        NotFound,
    },
    computed: {
        ...mapState('easterEggs', [
            'challengeAvailable',
        ]),
        detailed() {
            return Object.keys(this.$route.params).length;
        },
    },
    created() {
        this.loadParticipantProgress();
    },
    methods: {
        ...mapActions('easterEggs', [
            'loadParticipantProgress',
        ]),
    },
};
</script>

<template>
    <div class="clnt-widget"
         v-click-outside="hideDropdown"
         :class="{
             'clnt-widget_active': isActive,
             'clnt-widget_desktop': mqDesktop,
             '-hide': hideDesktop,
         }">
        <button class="clnt-widget__btn"
                aria-label="Leads Coins"
                @click="toggleDropdown()">
            <span class="clnt-widget__info">
                <SvgIcon name="leads-coins" />
                <span class="clnt-widget__balance-short">{{ formatCoinsShort(accountBalance) }}</span>
            </span>
        </button>
        <div class="clnt-widget__body">
            <div class="clnt-widget__popup">
                <div class="clnt-widget__popup-caption">
                    Leads.Coins
                </div>
                <div class="clnt-widget__popup-content">
                    <div class="clnt-widget__popup-info">
                        <div class="clnt-widget__popup-label">
                            Баланс Leads.Coins:
                        </div>
                        <div class="clnt-widget__popup-value clnt-widget__popup-value_lg">
                            <span class="lds-coins lds-coins_lg">
                                <span class="clnt-widget__balance">{{ formatCoins(accountBalance) }}</span>
                                <div class="lds-coins__easter-egg"
                                     v-if="!!currentEasterEgg && currentEasterEgg.needShow"
                                     @click="collect(currentEasterEgg)">
                                    <SvgIcon name="leads-coins" />
                                </div>

                                <SvgIcon name="leads-coins"
                                         v-else />
                            </span>
                        </div>
                    </div>
                    <div class="clnt-widget__popup-text">
                        <p>
                            Leads.Coins - бонусные баллы, которые можно потратить в нашем внутреннем
                            <router-link :to="{name: 'MarketCatalog'}"
                                         class="lds-link">
                                магазине призов.
                            </router-link>
                        </p>
                        <p>Leads.Coins начисляются за выполнение заданий по улучшению офферов.</p>
                    </div>
                    <div class="clnt-widget__popup-actions">
                        <router-link :to="{name: 'MarketCatalog'}"
                                     class="clnt-widget__popup-btn lds-btn lds-btn_fill -blue">
                            В магазин
                        </router-link>
                        <router-link :to="{name: 'Tasks'}"
                                     class="clnt-widget__popup-btn lds-btn lds-btn_fill -gray">
                            Задания
                        </router-link>
                        <router-link :to="{name: 'AccrualHistory'}"
                                     class="clnt-widget__popup-btn lds-btn lds-btn_fill -gray">
                            История
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import vClickOutside from 'v-click-outside';
import SvgIcon from '@/components/common/SvgIcon.vue';
import formattingCoins from '@/mixins/formattingCoins';
import {tour1egg3} from '@/models/EasterEggs/EasterEgg';

export default {
    name: 'HeaderWidgetBalance',
    components: {
        SvgIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    mixins: [
        formattingCoins,
    ],
    data() {
        return {
            isActive: false,
            hideDesktop: false,
        };
    },
    computed: {
        ...mapState('account', [
            'accountBalance',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        currentEasterEgg() {
            return this.getAvailable(tour1egg3);
        },
        mqDesktop() {
            return this.$mq === 'xl';
        },
    },
    watch: {
        $route() {
            this.hideDropdown();
            this.hideDesktop = true;
            setTimeout(() => {
                this.hideDesktop = false;
            }, 400);
        },
        mqDesktop(newValue) {
            if (!newValue) {
                this.hideDropdown();
            }
        },
    },
    created() {
        this.check(tour1egg3);
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        toggleDropdown() {
            if (!this.mqDesktop) {
                this.isActive = !this.isActive;
            }
        },
        hideDropdown() {
            this.isActive = false;
        },
    },
};
</script>

<template>
    <div class="lds-page"
         v-cloak>
        <HeaderBar class="lds-page__header" />
        <main class="lds-page__body">
            <div class="lds-heading lds-container"
                 v-if="breadcrumbs.length">
                <Breadcrumbs />
            </div>
            <router-view />
        </main>
        <easter-eggs-modal />
        <easter-eggs-next-step-modal />
        <Footer class="lds-page__footer" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import HeaderBar from '@/components/layout/Header/HeaderBar';
import Breadcrumbs from '@/components/layout/Breadcrumbs';
import Footer from '@/components/layout/Footer';
import EasterEggsModal from '@/components/easterEggs/easterEggsModal/index';
import EasterEggsNextStepModal from '@/components/easterEggs/easterEggsNextStepModal/index';

export default {
    name: 'LayoutDefaultWithoutHeading',
    components: {
        HeaderBar,
        Breadcrumbs,
        EasterEggsModal,
        EasterEggsNextStepModal,
        Footer,
    },
    computed: {
        ...mapState('common', [
            'breadcrumbs',
        ]),
    },
};
</script>

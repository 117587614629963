<template>
    <div :class="classNameItem">
        <router-link :to="item.path"
                     custom
                     v-slot="{ href, navigate, isActive, isExactActive }">
            <a :href="href"
               :class="[classNameLink, isActive && '-active', isExactActive && '-exact-active']"
               v-if="!navList.length"
               @click="navigate">
                {{ item.meta.nav.name }}
            </a>
            <div :class="[classNameLink, isActive && '-active', isExactActive && '-exact-active']"
                 v-else>
                {{ item.meta.nav.name }}
            </div>
        </router-link>
        <div class="clnt-nav__submenu"
             :class="classSubmenuModifier"
             v-if="navList.length">
            <HeaderNavTree v-for="(child, index) in navList"
                           :key="index"
                           :depth="depth + 1"
                           :item="child" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderNavTree',
    props: {
        item: {
            type: Object,
            required: true,
        },
        depth: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        navList() {
            if (this.item.children) {
                const list = this.item.children.filter(this.isNav);
                if (this.item.childrenNav) {
                    list.push(...this.item.childrenNav);
                }
                return list;
            }
            return [];
        },
        classNamePrefix() {
            let name = 'clnt-nav__menu-';
            if (this.depth > 1) {
                name = 'clnt-nav__submenu-';
            }
            return name;
        },
        classSubmenuModifier() {
            return 'clnt-nav__submenu_level-' + this.depth;
        },
        classNameItem() {
            return this.classNamePrefix + 'item';
        },
        classNameLink() {
            return this.classNamePrefix + 'link';
        },
    },
    methods: {
        isNav(item) {
            return item.hasOwnProperty('meta') && item.meta.hasOwnProperty('nav');
        },
        isChild(item) {
            return item.hasOwnProperty('children');
        },
    },
};
</script>

<template>
    <div class="container-market"
         v-if="false">
        <div class="faq-market">
            <div class="faq-market__title"
                 v-if="isFaqload">
                {{ isEmpty ? 'Вопросы не найдены' : ' Ответы на частые вопросы' }}
            </div>
            <div class="faq-market__wrapper">
                <div class="faq-market__questions"
                     v-if="faq">
                    <div class="faq-market__questions_wrapper">
                        <div v-for="(qa, index) in faq"
                             :key="'faq' + index">
                            <div class="faq-market__questions_item"
                                 :class="{'active': qa.active}"
                                 @click="toggleQA(qa)">
                                <div v-html="qa.question" />
                                <div class="faq-market__arrow">
                                    <i v-if="!arrow"
                                       :class="{'faq-market__arrow_top': qa.active}" />
                                    <i v-if="arrow" />
                                </div>
                            </div>
                            <transition name="fade"
                                        v-if="qa.active">
                                <div class="faq-market__questions_content"
                                     v-html="qa.answer" />
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'FAQ',
    data() {
        return {
            arrow: false,
        };
    },
    computed: {
        ...mapState([
            'faq',
            'faqIsload',
        ]),
        isEmpty() {
            return this.faq.length === 0;
        },
        isFaqload() {
            return this.faqIsload;
        },
    },
    mounted() {
        if (this.isEmpty) {
            this.loadFaq().catch(error => {
                throw error;
            });
        }
    },
    methods: {
        ...mapActions({
            loadFaq: 'LOAD_FAQ',
        }),
        toggleQA(qa) {
            Vue.set(
                qa, 'active', !qa.active,
            );
        },
    },
};
</script>

<template>
    <div v-scroll-lock="scrollLock"
         class="lds-modal-wrap">
        <div class="lds-modal-wrap__wrap">
            <button class="lds-modal-wrap__overlay"
                    type="button"
                    aria-label="Закрыть"
                    @click="close" />
            <div class="lds-modal-wrap__body">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    data() {
        return {
            scrollLock: false,
        };
    },
    mounted() {
        this.scrollLock = true;
    },
    beforeDestroy() {
        this.scrollLock = false;
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>

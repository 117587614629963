<template>
    <div class="lds-app"
         v-cloak>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'LayoutEmpty',
};
</script>

<template>
    <div class="clnt-widget"
         v-if="accountInfo"
         v-click-outside="hideDropdown"
         :class="{
             'clnt-widget_active': isActive,
             'clnt-widget_desktop': mqDesktop,
         }">
        <button class="clnt-widget__btn"
                aria-label="Профиль"
                @click="toggleDropdown()">
            <span class="clnt-widget__ico -profil">
                <SvgIcon name="profil" />
            </span>
        </button>
        <div class="clnt-widget__body">
            <div class="clnt-widget__popup">
                <div class="clnt-widget__popup-caption">
                    Профиль
                </div>
                <div class="clnt-widget__popup-content">
                    <div class="clnt-widget__popup-grid">
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                ID пользователя:
                            </div>
                            <div class="clnt-widget__popup-value"
                                 data-test="id">
                                {{ accountInfo.id }}
                            </div>
                        </div>
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                Компания:
                            </div>
                            <div class="clnt-widget__popup-value"
                                 data-test="name">
                                {{ accountInfo.name }}
                            </div>
                        </div>
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                Почта:
                            </div>
                            <div class="clnt-widget__popup-value"
                                 data-test="email">
                                {{ accountInfo.email }}
                            </div>
                        </div>
                        <div class="clnt-widget__popup-info">
                            <div class="clnt-widget__popup-label">
                                Телефон:
                            </div>
                            <div class="clnt-widget__popup-value"
                                 data-test="phone">
                                {{ accountInfo.phone }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clnt-widget__popup-footer">
                    <button class="clnt-widget__popup-control"
                            data-test="logout"
                            @click="logOut()">
                        Выйти
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import vClickOutside from 'v-click-outside';
import SvgIcon from '@/components/common/SvgIcon.vue';

export default {
    name: 'HeaderWidgetAccount',
    components: {
        SvgIcon,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            isActive: false,
        };
    },
    computed: {
        ...mapState('authorization', [
            'credential',
        ]),
        ...mapState('account', [
            'accountInfo',
        ]),
        mqDesktop() {
            return this.$mq === 'xl';
        },
    },
    watch: {
        mqDesktop(newValue) {
            if (!newValue) {
                this.hideDropdown();
            }
        },
    },
    methods: {
        ...mapActions('authorization', [
            'signOut',
        ]),
        toggleDropdown() {
            if (!this.mqDesktop) {
                this.isActive = !this.isActive;
            }
        },
        hideDropdown() {
            this.isActive = false;
        },
        logOut() {
            this.signOut()
                .then(() => {
                    if (!this.credential) {
                        this.$router.push({
                            name: 'Authorization',
                        });
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
    },
};
</script>

<template>
    <div class="lds-page"
         v-cloak>
        <HeaderBar class="lds-page__header" />
        <main class="lds-page__body">
            <div class="lds-heading lds-container"
                 v-if="heading || breadcrumbs.length">
                <Breadcrumbs />
                <h1 class="lds-heading__title"
                    v-if="heading">
                    {{ heading }}

                    <SvgIcon class="lds-heading__egg"
                             name="chart"
                             v-if="!!easterTour2Egg4 && easterTour2Egg4.needShow"
                             @click.native="collectEasterTour2Egg4" />
                </h1>
            </div>
            <router-view />
        </main>
        <easter-eggs-modal />
        <easter-eggs-next-step-modal />
        <Footer class="lds-page__footer" />
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import HeaderBar from '@/components/layout/Header/HeaderBar';
import Breadcrumbs from '@/components/layout/Breadcrumbs';
import Footer from '@/components/layout/Footer';
import EasterEggsModal from '@/components/easterEggs/easterEggsModal/index';
import EasterEggsNextStepModal from '@/components/easterEggs/easterEggsNextStepModal/index';
import {tour2egg4} from '@/models/EasterEggs/EasterEgg';
import EasterEggCookies, {tour2Egg4statMade} from '@/models/EasterEggs/EasterEggCookies';
import SvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'LayoutDefault',
    components: {
        SvgIcon,
        EasterEggsModal,
        EasterEggsNextStepModal,
        HeaderBar,
        Breadcrumbs,
        Footer,
    },
    mounted() {
        if (this.$route.path === '/offers') {
            this.check(tour2egg4);
        }
    },
    computed: {
        ...mapState('common', [
            'heading',
            'breadcrumbs',
        ]),
        ...mapGetters('easterEggs', [
            'getAvailable',
        ]),
        easterTour2Egg4() {
            if (this.$route.path !== '/offers') {
                return null;
            }

            if (!EasterEggCookies.getState(tour2Egg4statMade)) {
                return null;
            }
            return this.getAvailable(tour2egg4);
        },
    },
    methods: {
        ...mapActions('easterEggs', [
            'check',
            'collect',
        ]),
        collectEasterTour2Egg4() {
            this.collect(this.easterTour2Egg4);
        },
    },
};
</script>
